<template>
    <div class="box">
        <h2>PDF Formulare</h2>
        <div class="body">
            <table>
                <tbody>
                    <tr v-for="doc in docs.pdfform" v-bind:key="doc.id">
                        <td>
                            <a :href="doc.url">{{ doc.label }}</a>
                        </td>
                        <td>
                            <ul>
                                <li v-for="assign in doc.assignments" v-bind:key="assign.id">
                                    <a :href="assign.url">{{ assign.label }}</a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h2>LaTeX</h2>
        <div class="body">
            <table>
                <tbody>
                    <tr v-for="doc in docs.latex" v-bind:key="doc.id">
                        <td>
                            <a :href="doc.url">{{ doc.label }}</a>
                        </td>
                        <td>
                            <ul>
                                <li v-for="assign in doc.assignments" v-bind:key="assign.id">
                                    <a :href="assign.url">{{ assign.label }}</a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            docs: {
                pdfform: [],
                latex: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/docs/assignments").then(
                response => {
                    this.docs = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Zuordnungen");
                }
            );
        }
    }
};
</script>
